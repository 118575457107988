"use client";

import React, { useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ibmPlexMono from "./font";
import "./globals.css";
import Navbar from "@/components/navbar/navbar";
import { AuthContextProvider } from "@/app/context/AuthContext";
import SubscriptionContextProvider from "@/app/context/SubscriptionContext";
import Header from "@/components/responsiveHeader/Header";
import { getDataFromDatabase } from "@/services/authservice/credientalmanagement";
import { GOOGLE_OAUTH_CLIENT_ID } from "../constants";

function RootLayout({ searchModal, modal, children, workspace }) {
  const clientId = GOOGLE_OAUTH_CLIENT_ID;
  const router = useRouter();
  const [token, setToken] = useState(null);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const queryClient = new QueryClient();

  const handleKeyDown = (e) => {
    if (e.keyCode === 75 && e.metaKey) {
      router.push("/search");
    }
    if (e.keyCode === 27) {
      router.back();
    }
  };
  useEffect(() => {
    (async () => {
      await getDataFromDatabase()
        .then((data) => {
          setToken(data);
        })
        .catch(() => {});
    })();
  }, []);
  return (
    <html lang="en" className={ibmPlexMono.variable}>
      {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-noninteractive-tabindex */}
      <body
        className="custom-body relative flex h-screen flex-col overflow-y-auto bg-black border border-gray-400/25"
        onKeyDown={handleKeyDown}
        tabIndex="0"
      >
        {/* eslint-enable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-noninteractive-tabindex */}
        <AuthContextProvider>
          <SubscriptionContextProvider>
            <GoogleOAuthProvider clientId={clientId}>
              <QueryClientProvider client={queryClient}>

                {token && (
                  <Header isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />
                )}
                <div className="flex w-full flex-1 flex-row place-items-stretch place-self-stretch font-mono">
                  <Navbar setIsNavOpen={setIsNavOpen} />
                  {workspace}
                </div>
                {searchModal}
                {modal}
                <div id="modal-root" />
              </QueryClientProvider>
            </GoogleOAuthProvider>
          </SubscriptionContextProvider>
        </AuthContextProvider>
      </body>
    </html>
  );
}

export default RootLayout;
